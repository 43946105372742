
  /**
   * (c) 2021, Fujitsu General. All rights reserved
   * Author: Guillermo Gonzalez
   * Date: 9, August 2021
   *
   * Summary: My Profile Dashboard
   *
   */
  import { Component, Vue } from 'vue-property-decorator';
  import { Action, namespace } from 'vuex-class';
  import { getErrorMessage, supportedLanguages } from '@/utils/Helpers';
  import AscxUserProfileDetails from '@rdss-mono/ascx-user-profile-details';
  import AscxUserProfileEdit from '@rdss-mono/ascx-user-profile-edit';
  import ChangePassword from '@rdss-mono/ascx-change-password';
  import { AccountRoles } from '@rdss-mono/ascx-types';
  import { pick } from 'lodash';

  const settingsModule = namespace('settings');

  @Component({
    components: {
      AscxUserProfileDetails,
      ChangePassword,
      AscxUserProfileEdit
    }
  })
  export default class MyProfileDashboard extends Vue {
    @Action updatePassword;
    @settingsModule.Action updateUser;
    @settingsModule.Action getUser;

    readonly changePasswordIcon = require('./assets/images/change-password.svg');
    readonly editIcon = require('./assets/images/edit.svg');
    private changePasswordError = '';
    private editUserError = '';
    private savingUser = false;
    private showEditModal = false;
    private showPasswordModal = false;
    private userFormData = {};
    private userEmailData = '';
    private language = supportedLanguages;
    readonly accountRoles = [AccountRoles.ADMIN, AccountRoles.MANAGER];
    mounted() {
      this.fetchUser();
    }

    get me(): any {
      return this.$store.getters.user;
    }
    private translate(key: string): string {
      return this.$t(key).toString();
    }
    private async changePassword(payload): Promise<void> {
      const localData = JSON.parse(localStorage.getItem('acme-session'));
      try {
        await this.updatePassword({
          username: this.$store.getters.user.email_address,
          data: {
            ...payload,
            access_token: localData.access_token
          }
        });
        this.resetPasswordError();
      } catch (error) {
        if (
          getErrorMessage(error) !==
          'rest_api.response.error.USER_ACCOUNT_NOT_FOUND'
        ) {
          this.changePasswordError = this.$t(getErrorMessage(error)).toString();
        } else {
          this.changePasswordError = this.$t(
            `rest_api.response.error.change_password.USER_ACCOUNT_NOT_FOUND`
          ).toString();
        }
      }
    }
    resetPasswordError(): void {
      this.showPasswordModal = false;
      this.changePasswordError = '';
    }

    /**
     * Updates user
     */
    private editUser({ user, email }): void {
      (async () => {
        try {
          const userModify = this.modifiedFields(user);
          this.savingUser = true;
          await this.updateUser({
            user: userModify,
            email
          });
          this.cancelEditModal();
          this.fetchUser();
        } catch (error) {
          this.savingUser = false;
          if (
            error.response.data.status_msg_key ===
            'rest_api.response.error.USER_ACCOUNT_IN_USE'
          ) {
            this.editUserError = `${this.$t(
              'errors.user_email_address_exist'
            )}`;
          } else {
            this.editUserError = `${this.$t(getErrorMessage(error))}`;
          }
        }
      })();
    }

    private cancelEditModal() {
      this.showEditModal = false;
      this.editUserError = '';
    }

    private openEditModal() {
      this.userFormData = pick(this.me, [
        'role',
        'name',
        'email_address',
        'mobile_phone',
        'mfa_method',
        'language'
      ]);
      this.userEmailData = this.userFormData['email_address'];
      this.showEditModal = true;
    }
    /**
     * Returns an object with only the modified
     * form fields.
     */
    private modifiedFields(user) {
      const originalFields = { ...user };
      const modified = {};

      Object.keys(originalFields).forEach((key) => {
        if (originalFields[key] !== this.me[key]) {
          modified[key] = user[key];
        }
      });

      return modified;
    }
    /**
     * Updates user vuex store after a save operation.
     */
    private fetchUser(): void {
      (async () => {
        try {
          const acceptedTos = this.me.accepted_tos;
          const { email_address } = this.me;
          const { data } = await this.getUser(email_address);
          this.$store.dispatch('loadUserData', {
            ...data.user_info,
            accepted_tos: acceptedTos,
            forceLanguage: this.savingUser
          });

          // update current localstorage value of language
          const session = JSON.parse(localStorage.getItem('acme-session'));
          session.user.language = data.user_info.language;
          localStorage.setItem('acme-session', JSON.stringify(session));
          //
          this.savingUser = false;
        } catch (error) {
          this.editUserError = `${this.$t(getErrorMessage(error))}`;
          this.savingUser = false;
        }
      })();
    }
  }
